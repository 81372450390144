import React, { useState } from 'react';
import { Form, Container, Button, Alert } from 'react-bootstrap';
import CustomNavbar from './Navbar'; // Adjust the import path
import './HalfYearlyPlans.css'; // Import the custom CSS file
import axios from 'axios'; // Make sure to install axios using `npm install axios`
import 'bootstrap/dist/css/bootstrap.min.css';

const HalfYearlyPlans = () => {
    // Initialize state for each form field
    const [formData, setFormData] = useState({
        Plan_Name: '',
        installation_charges: '',
        plan_charges: '',
        bandwidth: '',
        data: '',
        voice: '',
        ott_apps: '',
        live_tv: '',
        complaint_res_time: '',
        special_feature: ''
    });

    const [showForm, setShowForm] = useState(true); // Set to true to show form directly
    const [alert, setAlert] = useState({ show: false, variant: '', message: '' });

    // Handle input changes
    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const response = await axios.post('http://localhost:5000/api/addhalfPlan', formData);
            if (response.data.success) {
                setAlert({ show: true, variant: 'success', message: 'Plan added successfully!' });
                setFormData({
                    Plan_Name: '',
                    installation_charges: '',
                    plan_charges: '',
                    bandwidth: '',
                    data: '',
                    voice: '',
                    ott_apps: '',
                    live_tv: '',
                    complaint_res_time: '',
                    special_feature: ''
                });
            }
        } catch (error) {
            console.error('Error adding plan:', error.message);
            setAlert({ show: true, variant: 'danger', message: 'Failed to add plan. Please try again.' });
        }
    };

    return (
        <div>
            <CustomNavbar />
            <Container className="half-yearly-container">
                <h1>Half Yearly Plans</h1>

                {alert.show && (
                    <Alert variant={alert.variant} onClose={() => setAlert({ ...alert, show: false })} dismissible>
                        {alert.message}
                    </Alert>
                )}

                {showForm && (
                    <Form className="half-yearly-form" onSubmit={handleSubmit}>
                        <h2>Half Yearly Plan Form</h2>

                        <Form.Group controlId="Plan_Name">
                            <Form.Label className="form-label">Plan Name:</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter plan name"
                                className="form-control"
                                value={formData.Plan_Name}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="installation_charges">
                            <Form.Label className="form-label">Installation Charges:</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter installation charges"
                                className="form-control"
                                value={formData.installation_charges}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="plan_charges">
                            <Form.Label className="form-label">Plan Charges:</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter plan charges"
                                className="form-control"
                                value={formData.plan_charges}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="bandwidth">
                            <Form.Label className="form-label">Bandwidth:</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter bandwidth details"
                                className="form-control"
                                value={formData.bandwidth}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="data">
                            <Form.Label className="form-label">Data:</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter data details"
                                className="form-control"
                                value={formData.data}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="voice">
                            <Form.Label className="form-label">Voice:</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter voice details"
                                className="form-control"
                                value={formData.voice}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="ott_apps">
                            <Form.Label className="form-label">OTT Apps:</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter OTT apps details"
                                className="form-control"
                                value={formData.ott_apps}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="live_tv">
                            <Form.Label className="form-label">Live TV:</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter live TV details"
                                className="form-control"
                                value={formData.live_tv}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="complaint_res_time">
                            <Form.Label className="form-label">Complaint Resolution Time:</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter complaint resolution time"
                                className="form-control"
                                value={formData.complaint_res_time}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="special_feature">
                            <Form.Label className="form-label">Special Features:</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter special features"
                                className="form-control"
                                value={formData.special_feature}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>

                        <Button variant="primary" type="submit" className="submit-btn mt-3">
                            Submit
                        </Button>

                      <a href='/viewhalf-yearly'>
                      <Button variant="secondary" type="button" className="view-details-btn mt-3 ms-2" >
                            View Details
                        </Button>
                      </a>
                    </Form>
                )}
            </Container>
        </div>
    );
};

export default HalfYearlyPlans;