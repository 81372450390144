import React, { useState, useEffect } from 'react';
import { Container, Card, Button, Modal, Form, Alert } from 'react-bootstrap';
import axios from 'axios'; // Ensure axios is installed
import 'bootstrap/dist/css/bootstrap.min.css';
import './ViewHalfYearlyPlan.css'; // Import the custom CSS file

const ViewYearlyPlan = () => {
    const [plans, setPlans] = useState([]); // Initialize as empty array
    const [showModal, setShowModal] = useState(false);
    const [currentPlan, setCurrentPlan] = useState(null);
    const [formData, setFormData] = useState({});
    const [alert, setAlert] = useState({ show: false, variant: '', message: '' });

    useEffect(() => {
        const fetchPlans = async () => {
            try {
                const response = await axios.get('http://localhost:5000/api/getYearlyPlans');
                // Adjust this line to match the response structure
                if (response.data && response.data.data) {
                    setPlans(response.data.data); // Updated to use response.data.data
                } else {
                    console.error('Unexpected response structure:', response.data);
                }
            } catch (error) {
                console.error('Error fetching plans:', error.message);
            }
        };

        fetchPlans();
    }, []);

    const handleUpdateClick = (plan) => {
        setCurrentPlan(plan);
        setFormData(plan);
        setShowModal(true);
    };

    const handleDeleteClick = async (id) => {
        try {
            await axios.delete(`http://localhost:5000/api/deleteYearlyPlan/${id}`);
            setPlans(plans.filter(plan => plan.id !== id)); // Adjusted to use plan.id
            setAlert({ show: true, variant: 'success', message: 'Plan deleted successfully!' });
        } catch (error) {
            console.error('Error deleting plan:', error.message);
            setAlert({ show: true, variant: 'danger', message: 'Failed to delete plan. Please try again.' });
        }
    };

    const handleFormChange = (e) => {
        const { id, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.put(`http://localhost:5000/api/updateYearlyPlan/${currentPlan.id}`, formData); // Adjusted to use currentPlan.id
            setPlans(plans.map(plan => plan.id === currentPlan.id ? formData : plan)); // Adjusted to use plan.id
            setShowModal(false);
            setAlert({ show: true, variant: 'success', message: 'Plan updated successfully!' });
        } catch (error) {
            console.error('Error updating plan:', error.message);
            setAlert({ show: true, variant: 'danger', message: 'Failed to update plan. Please try again.' });
        }
    };

    return (
        <Container>
            <h1>View Yearly Plans</h1>

            {alert.show && (
                <Alert variant={alert.variant} onClose={() => setAlert({ ...alert, show: false })} dismissible>
                    {alert.message}
                </Alert>
            )}

            <div className="card-container">
                {plans.length > 0 ? (
                    plans.map(plan => (
                        <Card key={plan.id} className="plan-card mb-4">
                            <Card.Body>
                                <Card.Title>{plan.Plan_Name}</Card.Title>
                                <Card.Text>
                                    <strong>Installation Charges:</strong> {plan.installation_charges}<br />
                                    <strong>Plan Charges:</strong> {plan.plan_charges}<br />
                                    <strong>Bandwidth:</strong> {plan.bandwidth}<br />
                                    <strong>Data:</strong> {plan.data}<br />
                                    <strong>Voice:</strong> {plan.voice}<br />
                                    <strong>OTT Apps:</strong> {plan.ott_apps}<br />
                                    <strong>Live TV:</strong> {plan.live_tv}<br />
                                    <strong>Complaint Resolution Time:</strong> {plan.complaint_res_time}<br />
                                    <strong>Special Features:</strong> {plan.special_feature}
                                </Card.Text>
                                <Button variant="primary" onClick={() => handleUpdateClick(plan)}>Update</Button>
                                <Button variant="danger" className="ms-2" onClick={() => handleDeleteClick(plan.id)}>Delete</Button>
                                <a href='/half-yearly'><Button variant="primary" style={{marginLeft:'10px'}} >Add plan</Button>
                                </a>
                            </Card.Body>
                        </Card>
                    ))
                ) : (
                    <p>No plans available.</p>
                )}
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Plan</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleFormSubmit}>
                        {Object.keys(formData).map(key => (
                            key !== 'id' && ( // Exclude 'id' from the form
                                <Form.Group controlId={key} key={key} className="mb-3">
                                    <Form.Label>{key.replace('_', ' ').toUpperCase()}:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={formData[key]}
                                        onChange={handleFormChange}
                                        required
                                    />
                                </Form.Group>
                            )
                        ))}
                        <Button variant="primary" type="submit">
                            Update Plan
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default ViewYearlyPlan;
