import React, { useState } from 'react';
import Navbar from './Navbar'; // Adjust the import path
import './YearlyPlans.css'; // Import the custom CSS file
import axios from 'axios'; // Import axios for making HTTP requests

const YearlyPlans = () => {
    const [formData, setFormData] = useState({
        Plan_Name: '',
        installation_charges: '',
        plan_charges: '',
        bandwidth: '',
        data: '',
        voice: '',
        ott_apps: '',
        live_tv: '',
        complaint_res_time: '',
        special_feature: ''
    });

    const [showForm, setShowForm] = useState(true); // Default to true to show the form
    const [alert, setAlert] = useState({ show: false, message: '', variant: '' });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('http://localhost:5000/api/addYearlyPlan', formData);
            if (response.data.success) {
                setAlert({ show: true, variant: 'success', message: 'Plan added successfully!' });
                setFormData({
                    Plan_Name: '',
                    installation_charges: '',
                    plan_charges: '',
                    bandwidth: '',
                    data: '',
                    voice: '',
                    ott_apps: '',
                    live_tv: '',
                    complaint_res_time: '',
                    special_feature: ''
                });
            } else {
                setAlert({ show: true, variant: 'danger', message: 'Failed to add plan: ' + response.data.error });
            }
        } catch (error) {
            console.error('Error adding plan:', error.response ? error.response.data : error.message);
            setAlert({ show: true, variant: 'danger', message: 'Failed to add plan. Please try again.' });
        }
    };

    return (
        <div>
            <Navbar />
            <div className="yearly-plans-container">
                <h1>Yearly Plans</h1>

                {alert.show && <div className={`alert alert-${alert.variant}`}>{alert.message}</div>}

                {showForm && (
                    <form className="yearly-plans-form" onSubmit={handleSubmit}>
                        <h2>Yearly Plan Form</h2>
                        <div className="form-group">
                            <label className="form-label">Plan Name:</label>
                            <input
                                type="text"
                                className="form-control"
                                name="Plan_Name"
                                value={formData.Plan_Name}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label className="form-label">Installation Charges:</label>
                            <input
                                type="text"
                                className="form-control"
                                name="installation_charges"
                                value={formData.installation_charges}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label className="form-label">Plan Charges:</label>
                            <input
                                type="text"
                                className="form-control"
                                name="plan_charges"
                                value={formData.plan_charges}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label className="form-label">Bandwidth:</label>
                            <input
                                type="text"
                                className="form-control"
                                name="bandwidth"
                                value={formData.bandwidth}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label className="form-label">Data:</label>
                            <input
                                type="text"
                                className="form-control"
                                name="data"
                                value={formData.data}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label className="form-label">Voice:</label>
                            <input
                                type="text"
                                className="form-control"
                                name="voice"
                                value={formData.voice}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label className="form-label">OTT APPS:</label>
                            <input
                                type="text"
                                className="form-control"
                                name="ott_apps"
                                value={formData.ott_apps}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label className="form-label">Live TV:</label>
                            <input
                                type="text"
                                className="form-control"
                                name="live_tv"
                                value={formData.live_tv}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label className="form-label">Complaint Resolution Time:</label>
                            <input
                                type="text"
                                className="form-control"
                                name="complaint_res_time"
                                value={formData.complaint_res_time}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label className="form-label">Special Features:</label>
                            <input
                                type="text"
                                className="form-control"
                                name="special_feature"
                                value={formData.special_feature}
                                onChange={handleChange}
                            />
                        </div>
                        <button className="submit-btn" type="submit">
                            Submit
                        </button>
                       <a href='/view-yearly'>
                       <button className="view-details-btn" type="button" >
                            View Details
                        </button>
                       </a>
                    </form>
                )}
            </div>
        </div>
    );
};

export default YearlyPlans;
