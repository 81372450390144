import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import './Navbar.css';  // Import the updated custom CSS file

const CustomNavbar = () => {
    return (
        <Navbar className="custom-navbar" expand="lg">
            <Container>
                <Navbar.Brand href="#">Admin Panel</Navbar.Brand>
              
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="navbar-nav">
                        <LinkContainer to="/half-yearly">
                            <Nav.Link>Half Yearly Plans</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/monthly">
                            <Nav.Link>Monthly Plans</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/yearly">
                            <Nav.Link>Yearly Plans</Nav.Link>
                        </LinkContainer>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default CustomNavbar;
