import React, { useState } from 'react';
import { Form, Container, Button, Alert } from 'react-bootstrap';
import CustomNavbar from './Navbar'; // Adjust the import path
import axios from 'axios'; // Make sure to install axios using `npm install axios`
import './MonthlyPlans.css'; // Import the custom CSS file
import 'bootstrap/dist/css/bootstrap.min.css';

const MonthlyPlans = () => {
    // Initialize state for each form field
    const [formData, setFormData] = useState({
        Plan_Name: '',
        installation_charges: '',
        plan_charges: '',
        bandwidth: '',
        data: '',
        voice: '',
        ott_apps: '',
        live_tv: '',
        complaint_res_time: '',
        special_feature: ''
    });

    const [alert, setAlert] = useState({ show: false, variant: '', message: '' });

    // Handle input changes
    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Form data:', formData); // Log form data
        try {
            const response = await axios.post('http://localhost:5000/api/addMonthlyPlan', formData);
            console.log('Response:', response.data); // Log API response
            if (response.data.success) {
                setAlert({ show: true, variant: 'success', message: 'Plan added successfully!' });
                setFormData({
                    Plan_Name: '',
                    installation_charges: '',
                    plan_charges: '',
                    bandwidth: '',
                    data: '',
                    voice: '',
                    ott_apps: '',
                    live_tv: '',
                    complaint_res_time: '',
                    special_feature: ''
                });
            } else {
                setAlert({ show: true, variant: 'danger', message: 'Failed to add plan: ' + response.data.error });
            }
        } catch (error) {
            console.error('Error adding plan:', error.response ? error.response.data : error.message);
            setAlert({ show: true, variant: 'danger', message: 'Failed to add plan. Please try again.' });
        }
    };
    
    return (
        <div>
            <CustomNavbar />
            <Container className="monthly-container">
             

                {alert.show && (
                    <Alert variant={alert.variant} onClose={() => setAlert({ ...alert, show: false })} dismissible>
                        {alert.message}
                    </Alert>
                )}

                <Form className="monthly-form" onSubmit={handleSubmit}>
                    <h2>Monthly Plan Form</h2>

                    <Form.Group controlId="Plan_Name">
                        <Form.Label>Plan Name:</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter plan name"
                            value={formData.Plan_Name}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="installation_charges">
                        <Form.Label>Installation Charges:</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter installation charges"
                            value={formData.installation_charges}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="plan_charges">
                        <Form.Label>Plan Charges:</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter plan charges"
                            value={formData.plan_charges}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="bandwidth">
                        <Form.Label>Bandwidth:</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter bandwidth details"
                            value={formData.bandwidth}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="data">
                        <Form.Label>Data:</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter data details"
                            value={formData.data}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="voice">
                        <Form.Label>Voice:</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter voice details"
                            value={formData.voice}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="ott_apps">
                        <Form.Label>OTT Apps:</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter OTT apps details"
                            value={formData.ott_apps}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="live_tv">
                        <Form.Label>Live TV:</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter live TV details"
                            value={formData.live_tv}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="complaint_res_time">
                        <Form.Label>Complaint Resolution Time:</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter complaint resolution time"
                            value={formData.complaint_res_time}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="special_feature">
                    <Form.Label className="form-label">Special Features:</Form.Label>
                               <Form.Control
                                      type="text"
        placeholder="Enter special features"
        className="form-control"
        value={formData.special_feature}
        onChange={handleChange}
        required
    />
</Form.Group>


                    <Button variant="primary" type="submit" className="submit-btn mt-3">
                        Submit
                    </Button>

                    <Button variant="secondary" type="button" className="view-details-btn mt-3 ms-2" href="/view-monthly">
                        View Details
                    </Button>
                </Form>
            </Container>
        </div>
    );
};

export default MonthlyPlans;
