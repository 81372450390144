import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HalfYearlyPlans from './HalfYearlyPlans';
import MonthlyPlans from './MonthlyPlans';
import YearlyPlans from './YearlyPlans';
import ViewHalfYearlyPlan from './ViewHalfYearlyPlan';
import ViewMonthlyPlan from './ViewMonthlyPlans';
import ViewYearlyPlan from './ViewYearlyPlan';

function App() {
    return (
        <Router>
            <Routes>
                {/* Add a default route */}
                <Route path="/" element={<Navigate to="/monthly" />} />
                
                <Route path="/monthly" element={<MonthlyPlans />} />
                <Route path="/view-monthly" element={<ViewMonthlyPlan />} />
                <Route path="/half-yearly" element={<HalfYearlyPlans />} />
                <Route path="/viewhalf-yearly" element={<ViewHalfYearlyPlan/>} />
                <Route path="/yearly" element={<YearlyPlans />} />
                <Route path="/view-yearly" element={<ViewYearlyPlan/>} />
            </Routes>
        </Router>
    );
}

export default App;
